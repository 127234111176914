import ScrollToTop from "./components/global/ScrollToTop";
import HomePage from "./pages/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// pages
const pages = [{ path: "/", element: <HomePage /> }];

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {pages.map((page) => (
          <Route
            key={`page${page.path}`}
            path={page.path}
            element={page.element}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
