import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <main className="flex flex-col min-h-screen">
      {/* <Navbar /> */}
      <div className="flex-1 flex flex-col justify-center">{children}</div>
      {/* <Footer /> */}
    </main>
  );
};

export default Layout;
