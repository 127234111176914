import React from "react";
import Container from "../components/global/Container";
import Layout from "../components/global/Layout";

const HomePage = () => {
  return (
    <Layout>
      <section className="py-6">
        <Container>
          <div className="flex items-center justify-center text-center gap-8">
            <div>
              <span
                className="d-block hover:scale-105 hover:opacity-80 transition-all duration-500 max-h-80 w-80"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="d-block h-full w-full"
                  src="/assets/logo.png"
                  alt="..."
                />
              </span>
              <h1 className="mt-8 mb-0 font-bold text-3xl md:text-5xl capitalize">
                Maintenance
              </h1>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default HomePage;
